<template>
    <div class="pt-7 container-fluid text-right">
        <b-card>
            <b-row>
                <b-col md="12" class="text-left">
                    <template v-if="profile.ical && profile.ical.trim() != ''">
                        <b-row>
                            <b-col md="8">
                                <base-input :label="$t('calendar.ical_url')" readonly :value="profile.ical"></base-input>
                            </b-col>
                            <b-col md="4" class="pt-4" v-if="refreshStatus">
                                <base-button type="default" @click="refreshIcal" class="my--5" size="sm">
                                    <i class="fas fa-sync-alt"></i> {{ $t('calendar.update') }}
                                </base-button>
                            </b-col>
                        </b-row>
                    </template>
                </b-col>
                <b-col md="12" class="pt-4">
                    <base-button class="mb-3" size="sm" @click="handleClickSignIn" v-if="!isSignIn">
                        <i class="fab fa-google google"></i> {{ $t('general.google_calendar_permission') }}
                    </base-button>
                    <base-button type="danger" class="my--5" size="sm" @click="handleClickSignOut" v-else>
                        <i class="fab fa-google google text-light"></i> {{ $t('general.google_delete_permission') }}
                    </base-button>
                    <template v-if="!isSignIn">
                        <base-button type="success" @click="importCal" class="mb-3" size="sm">
                            <i class="fas fa-upload"></i> {{ $t('calendar.import') }}
                        </base-button>
                        <base-button type="primary" @click="exportCal(false)" class="mb-3" size="sm">
                            <i class="fas fa-download"></i> {{ $t('calendar.export') }}
                        </base-button>
                    </template>
                </b-col>
            </b-row>
        </b-card>
        <b-card no-body class="p-4">
            <!-- Takvim Görünümü -->
            <v-calendar class="custom-calendar max-w-full" :locale="userLang" :masks="masks" :attributes="attributes" disable-page-swipe is-expanded v-loading="loading" :min-date="new Date()"
                @update:to-page="changePage">
                <template v-slot:day-content="{ day, attributes }">
                    <div class="flex flex-col h-full z-10 overflow-hidden">
                        <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
                        <div class="flex-grow overflow-y-auto overflow-x-auto">
                            <p v-for="attr in attributes" class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1" :class="attr.customData.class">
                                {{ attr.customData.title }}
                            </p>
                        </div>
                    </div>
                </template>
            </v-calendar>
        </b-card>

        <modal :show.sync="importModal.status" modal-classes="modal-secondary" size="sm" :showClose="false" :backdropClose="false" :hideFooter="true">
            <validation-observer v-slot="{ handleSubmit, errors, invalid }" ref="formValidator">
                <h6 slot="header" class="modal-title">{{ $t('calendar.import_cal') }}</h6>
                <hr>
                <b-form role="form" @submit.prevent="handleSubmit(onSubmitImport)" v-loading="importModal.loading">
                    <div class="form-group">
                        <base-input type="text" name="url" :nameAs="$t('calendar.url')" :label="$t('calendar.url') + '*'" :placeholder="$t('calendar.url')" v-model="importModal.data.url"
                            :rules="{ required: true }"></base-input>
                    </div>
                    <b-alert show variant="warning" v-if="invalid">
                        <strong>{{ $t('warning.fill_title') }} : </strong> {{ $t('warning.fill') }}
                    </b-alert>
                    <base-button type="primary" native-type="submit" :disabled="importModal.loading || invalid || (importModal.type == 'file' && !importModal.data.upload)">
                        {{ $t('general.create') }}
                    </base-button>
                    <base-button type="link text-danger" class="ml-auto float-right" @click="importModal.status = false" :disabled="importModal.loading">
                        {{ $t('close') }}
                    </base-button>
                </b-form>
            </validation-observer>
        </modal>

        <modal :show.sync="exportModal.status" modal-classes="modal-secondary" size="sm" :showClose="false" :backdropClose="false" :hideFooter="true">
            <h6 slot="header" class="modal-title">{{ $t('calendar.export_cal') }}</h6>
            <div class="form-group text-center">
                <base-button type="default" size="sm" class="mb-3" @click="exportCal(true)" v-if="refreshStatus">
                    <i class="fas fa-sync-alt"></i> {{ $t('calendar.generate_share_url') }}
                </base-button>
                <base-input :label="$t('calendar.share_url')" :value="shareUrl" v-if="shareUrl && shareUrl != ''"></base-input>
            </div>
            <base-button type="link text-danger" class="ml-auto float-right" @click="exportModal.status = false" :disabled="exportModal.loading">
                {{ $t('close') }}
            </base-button>
        </modal>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { CALENDAR_REQUEST, CALENDAR_ADD, CALENDAR_SHARE } from '@/store/modules/define';
import { handleError } from '@/utils/func';

let self;

export default {
    computed: {
        ...mapGetters({ calendar: 'getCalendar', profile: 'getProfile', shareUrl: 'getShareUrl' }),
        ...mapState(['userLang']),
        attributes() {
            return self.calendar.map((date, index) => ({
                key: index,
                customData: {
                    title: moment(date.start).format('HH:mm') + '-' + moment(date.end).format('HH:mm') + ' ' + date.summary,
                    class: 'bg-calendar' + (date.appointment ? '-appointment' : '')
                },
                dates: { start: date.start, end: date.end }
            }));
        }
    },
    data() {
        return {
            isInit: false,
            isSignIn: false,
            refreshStatus: false,
            importModal: {
                type: 'url',
                status: false,
                loading: false,
                data: {
                    url: '',
                    upload: null
                }
            },
            exportModal: {
                status: false,
                loading: false
            },
            loading: false,
            masks: {
                weekdays: 'WWW'
            },
            date: {
                start: parseInt(moment().clone().startOf('month').format('YYYYMMDD')),
                end: parseInt(moment().clone().startOf('month').add(1, 'month').subtract(1, 'day').format('YYYYMMDD'))
            }
        };
    },
    methods: {
        handleClickSignIn() {
            const GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth`;
            const params = new URLSearchParams({
                client_id: process.env.VUE_APP_CLIENT_ID,
                redirect_uri: 'https://admin-panel.' + this.getDomain(window.location.hostname) + ':8081/oauth2callback',
                response_type: 'code',
                scope: 'https://www.googleapis.com/auth/calendar',
                access_type: 'offline',
                prompt: 'consent',
            });

            window.location.href = `${GOOGLE_AUTH_URL}?${params.toString()}`;
        },
        getDomain(hostname) {
            const regex = /(?:[\w-]+\.)?([\w-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)(?::\d+)?$/;
            const match = hostname.match(regex);
            return match ? match[1] : null;
        },
        async handleClickSignOut() {
            google.accounts.id.disableAutoSelect();
            this.isSignIn = false;
            console.log('User signed out');
        },
        changePage(data) {
            const startOfMonth = moment(`${data.year}-${data.month}`, 'YYYY-MM')
                .clone()
                .startOf('month')
                .format('YYYYMMDD');
            const endOfMonth = moment(`${data.year}-${data.month}`, 'YYYY-MM')
                .clone()
                .endOf('month')
                .format('YYYYMMDD');
            this.date.start = startOfMonth;
            this.date.end = endOfMonth;

            this.getData();
        },
        getData() {
            self.loading = true;
            self.$store
                .dispatch(CALENDAR_REQUEST, { start: self.date.start, end: self.date.end })
                .then((resp) => {
                    self.loading = false;
                })
                .catch((err) => {
                    self.loading = false;
                    handleError(self, err);
                });
        },
        importCal() {
            self.importModal.status = true;
            self.importModal.type = 'url';
            self.importModal.data = {
                url: '',
                upload: null
            };
        },
        exportCal(generate = false) {
            self.exportModal.status = true;
            if (!self.shareUrl || self.shareUrl == '' || generate) {
                self.exportModal.loading = true;
                let data = {};
                if (generate) {
                    data['generate'] = true;
                }
                self.$store
                    .dispatch(CALENDAR_SHARE, data)
                    .then((resp) => {
                        self.exportModal.loading = false;
                    })
                    .catch((err) => {
                        self.exportModal.status = false;
                        self.exportModal.loading = false;
                        handleError(self, err);
                    });
            }
        },
        refreshIcal() {
            self.importModal.type = 'url';
            self.importModal.data['url'] = self.profile.ical;
            self.loading = true;
            self.onSubmitImport();
        },
        onSubmitImport() {
            self.importModal.loading = true;
            let data = { type: self.importModal.type };
            if (self.importModal.type == 'url') {
                data['url'] = self.importModal.data.url;
            } else {
                data['file'] = self.importModal.data.upload;
            }
            self.$store
                .dispatch(CALENDAR_ADD, data)
                .then((resp) => {
                    self.loading = false;
                    self.importModal.loading = false;
                    self.importModal.status = false;

                    self.getData();
                    self.profile.ical = self.importModal.type == 'url' ? self.importModal.data.url : '';
                })
                .catch((err) => {
                    self.loading = false;
                    self.importModal.loading = false;
                    handleError(self, err);
                });
        },
        async checkGoogleTokenValidity(refreshToken) {
            try {
                const response = await fetch('https://oauth2.googleapis.com/token', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    body: new URLSearchParams({
                        client_id: process.env.VUE_APP_CLIENT_ID,
                        client_secret: process.env.VUE_APP_CLIENT_SECRET,
                        refresh_token: refreshToken,
                        grant_type: 'refresh_token',
                    }).toString(),
                });

                const data = await response.json();

                if (data.access_token) {
                    console.log('Google bağlantısı geçerli');
                    return true; // Refresh token geçerli
                } else {
                    console.error('Google bağlantısı geçersiz', data);
                    return false; // Refresh token geçersiz
                }
            } catch (error) {
                console.error('Token geçerliliği kontrolü sırasında hata:', error);
                return false;
            }
        },
        async validateGoogleSignIn() {
            const isValid = await this.checkGoogleTokenValidity(this.profile.googleRefreshToken);
            this.isSignIn = isValid;
            this.refreshStatus = isValid;
        }
    },
    mounted() {
        setTimeout(() => {
            if (self.profile.googleRefreshToken) {
                self.validateGoogleSignIn();
            }
        }, 1000);
    },
    created() {
        self = this;
        self.isInit = true;
    }
};
</script>


<style lang="scss">
.custom-calendar.vc-container {
    --day-border: 1px solid #b8c2cc;
    --day-border-highlight: 1px solid #b8c2cc;
    --day-width: 90px;
    --day-height: 90px;
    --weekday-bg: #f8fafc;
    --weekday-border: 1px solid #eaeaea;
    border-radius: 0;
    width: 100%;

    & .vc-header {
        background-color: #f1f5f8;
        padding: 10px 0;
    }

    & .vc-weeks {
        padding: 0;
    }

    & .vc-weekday {
        background-color: var(--weekday-bg);
        border-bottom: var(--weekday-border);
        border-top: var(--weekday-border);
        padding: 5px 0;
    }

    & .vc-day {
        padding: 0 5px 3px 5px;
        text-align: left;
        height: var(--day-height);
        min-width: var(--day-width);
        background-color: white;
        overflow-x: auto;

        &.is-not-in-month {
            display: none;
        }

        &.weekday-1,
        &.weekday-7 {
            background-color: #eff8ff;
        }

        &:not(.on-bottom) {
            border-bottom: var(--day-border);

            &.weekday-1 {
                border-bottom: var(--day-border-highlight);
            }
        }

        &:not(.on-right) {
            border-right: var(--day-border);
        }
    }

    & .vc-day-dots {
        margin-bottom: 5px;
    }
}
</style>